
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import TicketDetailsSidebar from '@/components/pages/tickets/details/sidebar/TicketDetailsSidebar.vue'
import TicketDetailsMain from '@/components/pages/tickets/details/TicketDetailsMain.vue'
import { useRoute } from 'vue-router'
import { useModes } from '@/compositions/modes'
import { useTicketsGettingStarted } from '@/compositions/tickets/useTicketsGettingsStarted'
import DetailsTemplate from '@/components/shared/templates/details/DetailsTemplate.vue'

export default defineComponent({
  components: {
    DetailsTemplate,
    TicketDetailsMain,
    TicketDetailsSidebar,
  },
  setup() {
    const { getOpenLink } = useTicketsGettingStarted()
    const { isEmptyHalfMode } = useModes()
    const route = useRoute()
    const isDraft = route.params.isDraft === 'true'
    const isSolved = ref(false)
    const isSimulateTicket = isEmptyHalfMode.value

    const showCoworkers = ref(false)
    const editorState = ref(false)

    const breadcrumbs = computed(() => [
      { label: 'Tickets', url: isEmptyHalfMode.value ? { name: 'base.tickets.main.gettingStarted' } : { name: 'base.tickets' } },
      {
        label: isEmptyHalfMode.value ? 'Open' : 'Open email requests',
        url: isEmptyHalfMode.value ? getOpenLink.value.route : { name: 'base.tickets' },
      },
      { label: '3225343' },
    ])

    return {
      editorState,
      isDraft,
      isSolved,
      route,
      isSimulateTicket,
      showCoworkers,
      breadcrumbs,
    }
  },
})
